
<template>
  <div class="row align-items-center py-4">
    <div class="col-lg-6 col-12">
      <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
        <route-breadcrumb></route-breadcrumb>
      </nav>
    </div>
    <div class="col-lg-6 col-12 text-right">
      <base-button type="primary" @click="edit(position)" v-if="currentUserRights.includes('Dateneingabe_Ansicht') && hasFunction('D')" :disabled="project.state === 'ARCHIVED'">
        <i class="fas fa-edit"></i>
      </base-button>
      <!-- <base-button type="primary" @click="report()">
        <i class="fas fa-file-pdf"></i>
      </base-button> -->
      <base-split-button
        type="primary" class="mr-1"
        v-if="currentUserRights.includes('BHB - Startseite_Exportieren')"
        @primary-click="report(true, currentUser.lang)">
          <template v-slot:content>
            <i class="fas fa-file-pdf"></i>
          </template>
          <!-- <a class="dropdown-item" >Komplettes Dokument</a> -->
          <a class="dropdown-item" @click="report(false)">{{$t('message.without_prices')}}</a>
          <a class="dropdown-item" @click="report(true, 'en')">{{$t('message.english')}}</a>
          <a class="dropdown-item" @click="report(false, 'en')">{{$t('message.english_without_prices')}}</a>
      </base-split-button>
      <base-button type="primary" @click="handlePreviousClick" :disabled="!hasPrevious">
        <i class="fas fa-arrow-left"></i>
      </base-button>
      <base-button type="primary" @click="handleNextClick" :disabled="!hasNext">
        <i class="fas fa-arrow-right"></i>
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import download from '@/util/download'

export default {
  name: "page-header",
  props: {
    position: Object
  },
  computed: {
    ...mapGetters([
      "positions",
      "projectAddressPositions",
      "unrejectedIMPositions",
      "project",
      "currentUserRights",
      "currentUser"
    ]),
    hasNext() {
      const index = this.getPositionIndex();
      return index >= 0 && index < this.unrejectedIMPositions.length - 1;
    },
    hasPrevious() {
      return this.getPositionIndex() > 0;
    }
  },
  methods: {
    edit (row) {
      this.$router.push({ path: `/project/${this.$route.params.id}/data/${row.id}`})
    },
    hasFunction(func) {
      if (this.project) {
        return this.project.functions.includes(func);
      } else {
        return null;
      }
    },
    getPositionIndex() {
      const {
        position: { id },
        unrejectedIMPositions
      } = this;
      
      return unrejectedIMPositions
        .findIndex(pos => pos.id === id);
    },
    handleNextClick() {
      this.navigateToOffset(1);
    },
    handlePreviousClick() {
      this.navigateToOffset(-1);
    },
    navigateToOffset(offset) {
      let index = this.getPositionIndex();
      if (index === -1) {
        return;
      }

      index += offset;
      if (index < 0 || index >= this.unrejectedIMPositions.length) {
        return;
      }

      const target = this.unrejectedIMPositions[index];
      this.$router.push({
        path: `/project/${target.project_id}/bhb/${target.id}`
      });
    },
    report(prices = true, lang = 'de') {
      const page = this.getPositionIndex() + 1
      const url = new URL(`${this.$env.rootUrl}pdf/project/bhb/${this.project.id}/${this.position.id}`)
      if (!prices) {
        url.searchParams.set('prices', 'false')
      } 

      url.searchParams.set('logisticStatus', 'false')
      url.searchParams.set('lang', lang)

      download(url.toString(), this.project.name + "-IM-Seite-"+page+".pdf")
    }

  }
};
</script>
