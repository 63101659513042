<template>
	<modal @update:show="onClose" :show="show">
		<div v-loading="isLoading">
			<h6 class="modal-title" slot="header">
				{{ $t('message.export_options') }}
			</h6>
      		<div class="row mt-3 mb-3">
				<div class="col-md-3">
					<el-checkbox v-model="exportPrices">
						{{ $t('message.with_prices')}}
					</el-checkbox>
				</div>
				<div class="col-md-9" v-if="currentUserRights.includes('Kommissionierungsliste_Abhaken')">
					<el-checkbox v-model="exportLogistic">
						{{ $t('message.show_logistic_status')}}
					</el-checkbox>
				</div>
			</div>
			<div>
				<h6> {{ $t('message.language') }} </h6>
				<el-select v-model="exportLanguage">
					<el-option
						v-for="(lang, lang_index) in languages"
						:key="lang_index"
						:label="lang.label"
						:value="lang.value"
					/>
				</el-select>
			</div>
		</div>
		<template slot="footer">
			<base-button type="link" class="ml-auto" @click="onClose()">
				{{ $t('message.close') }}
			</base-button>
			<base-button type="primary" @click="onSave">
				{{ $t('message.export') }}
			</base-button>
		</template>
    </modal>
</template>

<script>

import { mapGetters } from 'vuex';

import download from '@/util/download';

export default {
	name: 'BHBExportForm',
	props: {
		show: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			exportLanguage: 'de',
			exportPrices: true,
			exportLogistic: false,
			languages: [
				{
					label: this.$t('message.lang_de'),
					value: 'de',
				},
				{
					label: this.$t('message.lang_en'),
					value: 'en'
				}
			],
			isLoading: false
		}
	},
	computed: {
		...mapGetters([
			'project',
			'currentProjectAddress',
        	'currentUserRights',
			'selectedIMPositions'
		])
	},
	methods: {
		async report(prices, lang) {
			try {
				this.isLoading = true;
				var offset = new Date().getTimezoneOffset();
				const src = 'pdf/project/bhb/'+this.project.id
				+ (this.selectedIMPositions.length > 0 ? `/${this.selectedIMPositions.join(',')}` : '')
				+ "?lang=" + lang
				+ "&tz=" + (-offset/60)
				+ (prices ? '' : '&prices=false')
				+ (this.exportLogistic ? '&logisticStatus=true' : '')
				+ ('&store=true')
				+ (this.currentProjectAddress != 0 ? '&adr=' + this.currentProjectAddress : '');

				this.$notify({
					verticalAlign: "top",
					horizontalAlign: "right",
					message: this.$t('message.export_start'),
					type: "info",
					timeout: 6000
				});
				return await download(src, this.project.name + "-BHB.pdf", false)
			} catch (e) {
				this.isLoading = false;
				this.$notify({
					verticalAlign: "top",
					horizontalAlign: "right",
					message: this.$t('message.export_pdf_error') + e.body.message,
					timeout: 5000,
					type: "error"
				});
			}
		},
		async reportPages() {
			this.loadingExport = true
			try {
				await Vue.http.get('pdf/project/bhb/'+this.project.id + '/pages', {})
				this.$notify({
					verticalAlign: "top",
					horizontalAlign: "right",
					message: this.$t('message.export_success'),
					type: "success"
				});

			} catch (e) {
				this.$notify({
					verticalAlign: "top",
					horizontalAlign: "right",
					message: this.$t('message.export_pages_error') + e.body.message,
					type: "error"
				});
			}
			this.loadingExport = false
		},
		async reportLabels() {
			this.loadingExport = true
			try {
				await download('pdf/project/bhb/'+this.project.id+'/labels', this.project.name + "-Labels.pdf")
			} catch (e) {
				this.$notify({
					verticalAlign: "top",
					horizontalAlign: "right",
					message: this.$t('message.export_labels_error') + e.body.message,
					type: "error"
				});
			}
			this.loadingExport = false
		},
		async onSave() {
			let ready = await this.report(this.exportPrices, this.exportLanguage)
			if (ready.ok) {
				this.isLoading = false;
				this.$emit('update:show', false);
			}
		},
		onClose(state) {
			this.$emit('update:show', state);
		}
	}
}
</script>