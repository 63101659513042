<template>
    <el-table
            :data="materials"
            class="table-responsive table-flush"
            header-row-class-name="thead-light"
            stripe>
      <el-table-column :label="$t('message.masse')" width="150">
        <template v-slot="{row}">
          {{row.width | number}} x {{row.height | number}} mm
        </template>
      </el-table-column>
      <el-table-column :label="$t('message.article')" width="230" >
        <template v-slot="{row}">
          {{row | material(currentLocale)}}
        </template>
      </el-table-column>
      <el-table-column :label="$t('data_input.category')" width="230" >
        <template v-slot="{row}">
          {{row | category(currentLocale)}}
        </template>
      </el-table-column>
      <el-table-column :label="$t('message.amount')" prop="quantity"></el-table-column>
      <el-table-column :label="$t('message.unitPrice')" align="right" min-width="120" v-if="isCustomerAdr && currentUserRights.includes('BHB - Vollbild_Ansicht mit Preis')">
        <template v-slot="{row}">
          {{row.price_unit | currency}}
        </template>
      </el-table-column>
      <el-table-column :label="$t('message.price_total')" align="right" min-width="120" v-if="isCustomerAdr && currentUserRights.includes('BHB - Vollbild_Ansicht mit Preis')">
        <template v-slot="{row}">
          {{row.price_total | currency}}
        </template>
      </el-table-column>
      <el-table-column :label="$t('message.weight')" prop="price_unit" align="right">
        <template v-slot="{row}">
          {{row.weight | number}} kg
        </template>
      </el-table-column>
      <el-table-column min-width="180" :label="$t('message.fire_protection_class')" prop="mat.brandschutz">
      </el-table-column>
      <el-table-column
        :label="$t('message.backNote')"
        width="300">
        <template v-slot="{row}">
          <el-row>
            <el-col :span="24">
              <el-select v-model="row.rueck_result_customer" @change="onResultChanged(row)" placeholder="Resultat" :disabled="!currentUserRights.includes('im.rueck_result_customer.canchange') || project.state === 'ARCHIVED'">
                <el-option v-for="option in results"
                            :key="option"
                            :label="option"
                            :value="option">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row v-if="row.user_rueck_ref">
            <el-col :span="24">
              {{row.user_rueck_ref.name}}, {{row.date_rueck | datetime}}
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
</template>

<script>
import { mapGetters } from "vuex";
import isCustomerAdr from '@/util/customerAdr'

export default {
  name: "materials",
  props: {
    materials: Array
  },
  data () {
    return {
      results: ['NOCH OFFEN', 'EINLAGERN', 'ENTSORGEN', 'ZUSENDEN']
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRights',
      'currentUser',
      'project',
      'position',
      'currentLocale'
    ]),
    isCustomerAdr() {
      return isCustomerAdr(this.currentUser, this.project, this.position.address)
    }
  },
  methods: {
    onResultChanged (material) {
      this.$emit('result-changed', material)
    }
  }
};
</script>
